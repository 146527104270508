import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  lng: localStorage.getItem("i18nextLng")
}

export const languageSlice = createSlice({
  name: "languageSwitch",
  initialState,
  reducers: {
    changeLanguageRedux: (state, action) => {
      state.lng = action.payload
    }
  }
})

export const { changeLanguageRedux } = languageSlice.actions
export default languageSlice.reducer
