import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api, dateFormat, nowDate } from "../Api"
const qs = require('qs');

export const getPage = createAsyncThunk(
  "getPage",
  async (arg: any) => {

    const query = qs.stringify(
      {
        populate: {
          'category': 'category',
          'cover': 'cover',
          'blocks': 'blocks',
          'image_gallery': 'image_gallery',
          'video_gallery': 'video_gallery',
          'localizations': '*'
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    const { data } = await Api.get(`/api/pages/${arg.id}?&locale=${arg.locale}&${query}`)
    return data
  }
)

export const getArticle = createAsyncThunk(
  "getArticle",
  async (arg: any) => {
    const query = qs.stringify(
      {
        populate: {
          'category': 'category',
          'cover': 'cover',
          'blocks': 'blocks',
          'gallery': 'gallery',
          'localizations': '*'
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    const { data } = await Api.get(
      `/api/articles/${arg.id}?locale=${arg.lng}&${query}`
    )
    return data
  }
)

export const getHomePageNews = createAsyncThunk("getHomePageNews", async (arg: any) => {
  const { data } = await Api.get(`/api/articles?locale=${arg}&filters[publishedDate][$lte]=${nowDate()}&populate=blocks,cover&sort=publishedDate:desc`)
  return data
})

export const getAllCategories = createAsyncThunk(
  "getAllCategories",
  async (arg: any) => {

    const query = qs.stringify(
      {
        populate: {
          'localizations': '*',
          'category': 'category',
          'cover': 'cover'
        },
        filters: {
          categories: arg.id,
          publishedDate: {
            $lte: nowDate(),
          },
        },
        sort: {
          publishedDate: 'desc'
        },
        pagination: { 
          start: arg.start || 0,
          limit: 1000
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    const { data } = await Api.get(
      `/api/articles/?${query}`
    )
    return data
  }
)

export const getSearchArticles = createAsyncThunk(
  "getSearchArticles",
  async (arg: any) => {

    const query = qs.stringify(
      {
        populate: {
          'localizations': '*',
          'category': 'category',
          'cover': 'cover',
          'blocks': 'blocks'
        },
        filters: {
          publishedDate: {
            $lte: nowDate(),
          }
        },
        sort: {
          publishedDate: 'desc'
        },
        pagination: { 
          start: arg.start || 0,
          limit: 1000
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    const { data } = await Api.get(
      `/api/articles/?${query}`
    )

    function stripHtml(html: string): string {
      // Use a regular expression to replace HTML tags with an empty string
      return html.replace(/<[^>]*>/g, '');
    }

    function fullTextSearchInHtml(query: string, htmlData: string): boolean {
      // First strip HTML tags
      const plainText = stripHtml(htmlData);
      
      // Perform a case-insensitive search in the plain text
      return plainText.toLowerCase().includes(query.toLowerCase());
    }

    const bodyRegex = new RegExp(`^<p>(.*?${arg.search})`, "i")
    const titleRegex = new RegExp(`^(${arg.search})`, "i")

    let filteredData = data.data.filter((news: any) => {
      let filterBlocks = news.attributes.blocks.filter((block: any) => {

        if (bodyRegex.test(block.body)) {
          return block;
        }
      });

      let filterTitle = titleRegex.test(news.attributes.title);

      if (filterBlocks.length || filterTitle ) {
        return news;
      }
    });

    return {
      data: filteredData
    }
  }
)

export const getCategory = createAsyncThunk(
  "getCategory",
  async (id: any) => {
  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

    const { data } = await Api.get(
      `/api/categories/${id}?&${query}`
    )
    return data
  }
)

export const getPopulatePosts = createAsyncThunk(
  "getPopularPosts",
  async (lng:any) => {
    const { data } = await Api.get(
      `/api/articles?locale=${lng}&filters[publishedDate][$lte]=${nowDate()}&populate=blocks,cover&sort=publishedDate:desc&pagination%5Blimit%5D=5`
    )
    return data
  }
)

export const getImageAndVideoGallery = createAsyncThunk(
  "getImageGallery",
  async (id: any) => {
    const { data } = await Api.get(
      `/api/pages/${id}?populate[image_gallery][populate]=*&populate=blocks&populate[video_gallery][populate]=*`
    )
    return data
  }
)

export const getArticleGallery = createAsyncThunk(
  "getArticleGallery",
  async (id: any) => {
    const { data } = await Api.get(
      `/api/articles/${id}?populate[blocks][populate]=*&populate[gallery][populate]=*&populate=cover`
    )
    return data
  }
)
