import { useTranslation } from "react-i18next";
import "./Fund.css"

export const Fund = (): JSX.Element => {
  const [t, i18n] = useTranslation();
  
  return (
    <div className="home-page-welcome">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="welcome-content">
              <header className="entry-header">
                <h2 className="entry-title">
                  {t('websiteTitle')}
                </h2>
              </header>
              <div className="entry-content mt-5">
                <p>
                  {t('websiteDescription')}
                </p>
              </div>
              <div className="entry-footer mt-5">
                <a href={t('aboutLink')} className="btn gradient-bg mr-2">
                  {t('seeMore')}
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 order-1 order-lg-2">

          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/YBwb8dljrE4?autoplay=1"
            srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,
            span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;
              text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
              </style><a href=https://www.youtube.com/embed/YBwb8dljrE4?autoplay=1>
              <img src=https://api.roundtable-act.am/uploads/poster_a9d98be928.png 
              alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="ԵՀԽ Հայաստանյան կլոր սեղան հիմնադրամ/Klor Seghan ARM"></iframe>
            {/* <iframe
              width="100%"
              height="380px"
              src="https://www.youtube.com/embed/wMhIw0uAohI"
              title="ԵՀԽ Հայաստանյան կլոր սեղան հիմնադրամ/Klor Seghan ARM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
    </div>
  )
}
