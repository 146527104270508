import axios from "axios"
import Moment from 'moment';
import { useAppSelector } from "./redux/hooks";
import { useEffect } from "react";
import { get } from "jquery";
import { getArticle } from "./redux/actions";

export const Api = axios.create({
  baseURL: "https://api.roundtable-act.am",
  // baseURL: "http://localhost:3080",
})

export const dateFormat = (date: Date) => {
    return Moment(date).format('DD-MM-YYYY')
}

export const nowDate = () => {
  return Moment(new Date()).format('yyy-MM-DD')
}

export const getMenuLocalization = (item: any, lng: any) => {

  if (lng != 'hy') {
    let getLocale = item.attributes.localizations?.data.find(((localization: any) => localization.attributes.locale === lng))

    if(getLocale) {
      // console.log(getLocale.attributes.menu_items)
      // getLocale.attributes.page = item.attributes.page;
      getLocale.attributes.category = item.attributes.category
      getLocale.attributes.menu_items = item.attributes.menu_items

      // console.log(getLocale)
      return getLocale;
    } else {
      return item
    }
  } else {
    return item
  }

}

export const getCategoryLocalization = (category: any, lng: any) => {

  if (lng != 'hy') {

    let getLocale: any = category.data?.attributes?.localizations?.data.find(((localization: any) => localization.attributes.locale === lng))

    if(getLocale) {
      let locale = {
        'data':getLocale
      }

      return locale;
    } else {
      return category
    }
  } else {
    return category
  }

}

export const getArticleLocalization = (article: any, lng: any) => {

  if (article.attributes.locale != lng) {

    let getLocale: any = article.attributes?.localizations?.data.find(((localization: any) => localization.attributes.locale === lng))

    if(getLocale) {

      let newObject = {
        ...getLocale,
        attributes: {
          ...getLocale.attributes,
          cover: getLocale.attributes.cover || article.attributes.cover,
          gallery: getLocale.attributes.gallery || article.attributes.gallery
        }
      }

      return newObject;
    } else {
      return article
    }
  } else {
    return article
  }

}

