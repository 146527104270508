import { createSlice } from "@reduxjs/toolkit"
import { getImageAndVideoGallery } from "./actions"

const initialState = {
  gallery: [],
  imageAndVideoGalleryLoading: false
}
export const ImageAndVideoGallerySlice = createSlice({
  name: "imageAndVideoGallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getImageAndVideoGallery.fulfilled, (state, action) => {
      state.gallery = action.payload
      state.imageAndVideoGalleryLoading = false
    })
    builder.addCase(getImageAndVideoGallery.pending, (state, action) => {
      state.imageAndVideoGalleryLoading = true
    })
    builder.addCase(getImageAndVideoGallery.rejected, (state, action) => {
      state.imageAndVideoGalleryLoading = false
    })
  }
})

export const {} = ImageAndVideoGallerySlice.actions

export default ImageAndVideoGallerySlice.reducer
