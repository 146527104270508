import { createSlice } from "@reduxjs/toolkit"
import { getSearchArticles } from "./actions"

const initialState = {
  articles: {},
  searchArticleLoading: false
}
export const getaSearchArticlesSlice = createSlice({
  name: "getSearchArticles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchArticles.fulfilled, (state, action) => {
      state.articles = action.payload
      state.searchArticleLoading = false
    })
    builder.addCase(getSearchArticles.pending, (state, action) => {
      state.searchArticleLoading = true
    })
  }
})

export const {} = getaSearchArticlesSlice.actions

export default getaSearchArticlesSlice.reducer
