import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getCategoryLocalization } from "../../Api";
import { getAllCategories, getCategory, getSearchArticles } from "../../redux/actions";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { PaginatedItems } from "../AllNews/PaginatedItems";
import './search.css'

export const Search = () => {
    const { search } = useParams()
    const { lng } = useAppSelector((store) => store.language)
    const [t, i18n] = useTranslation();
    let { searchArticles, category } = useAppSelector((state: any) => state)
    const dispatch = useAppDispatch()
  
    useEffect(() => {
      dispatch(getSearchArticles({ search, lng }))
    }, [search, lng])

    return (
      
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <p>{t('searchTitle')} {search}</p>
            <p>{t('found')} {searchArticles.articles.data?.length}  {t('result')}</p>
            <hr></hr>
            {searchArticles.articles.data ? (
              <React.Fragment key={searchArticles.articles.data.attributes}>
                <PaginatedItems itemsPerPage={25} items={searchArticles.articles.data}>
  
                </PaginatedItems>
            
              </React.Fragment>
            ) :  (
              <div className="loading">Loading...</div>
           ) }
          </div>
          {/* <Sidebar /> */}
        </div>
      </div>
      
    )
}
  