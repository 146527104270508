import "./videoGallery.css"

export const VideoGallery = ({ video }: any) => {
  const { description, title, url } = video

  return (
    <div className="videoGalleryItem">
      {title && (
        <h6>
          <strong>{title}</strong>
        </h6>
      )}

        <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${url}`}
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,
            span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;
              text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
              </style><a href=https://www.youtube.com/embed/${url}>
              <img src=https://img.youtube.com/vi/${url}/hqdefault.jpg
              alt=${title}><span>▶</span></a>"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture`}
            allowFullScreen
            title={`${title}`}></iframe>
      {description && <p>{description}</p>}
    </div>
  )
}
