import React, { useCallback } from "react"
import { useState, useEffect } from "react"
import Logo from "./logo.png"
import { Link } from "react-router-dom"
import "./Header.css"
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import { BiSolidDownArrow } from "react-icons/bi"
import { LanguageSwitch } from "../../components"
import { Loader } from "../../components/Loader"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../redux/hooks"
import { getMenuLocalization } from "../../Api"
import { SearchInput } from "../../components/SearchInput"

export const Header = ({ isHome }: { isHome: boolean }): JSX.Element => {
  const [t, i18n] = useTranslation();
  const { lng } = useAppSelector((store) => store.language)
  const [menuItems, setMenuItems] = useState([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchBoxState, setSearchBox] = useState("closed");
  const handleClick = () => {
    setIsOpen((pre) => !pre)
  }
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1300px)" })
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState<number>(0)
  const [scroll, setScroll] = useState(0)

  const checkRoute = useCallback((item: any) => {
    if (item.attributes.page?.data) {
      return `/${lng}/page/${item.attributes.page.data?.id}`
    } else if (item.attributes.category?.data) {
      return `/category/${item.attributes.category.data?.id}`
    } else if (item.attributes?.href) {
      return item.attributes.href
    } else {
      return "/"
    }
  }, [])

  const mobileSideBarHandler = (id: any) => {
    if (mobileSidebarOpen !== undefined && mobileSidebarOpen === id) {
      setMobileSidebarOpen(0)
    } else {
      setMobileSidebarOpen(id)
    }
  }

  const openSearchBox = () => {
    if (searchBoxState === "closed")  {
      console.log('aaa')
      setSearchBox("opened");
    } else { setSearchBox("closed") }

      console.log(searchBoxState)
  }

  const qs = require('qs');
  const query = qs.stringify(
    {
      populate: {
        'page': 'page',
        'category': 'category',
        'localizations': '*',
        'menu_items': {
          populate: { 
            'page': 'page',
            'category': 'category',
            'localizations': '*',
            'menu_items': {
              populate: {
                'page': 'page',
                'category': 'category',
                'localizations': {
                  populate: {
                    'page': 'page',
                    'category': 'category',
                }
              }
            }
            },  
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/menus?${query}`
      )
      .then(({ data: { data } }) => setMenuItems(data[0].attributes.menu_items.data))
  }, [])

  useEffect(() => {
    function handleScroll() {
      const currentScroll = window.scrollY
      setScroll(currentScroll)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [scroll])

  return (
    <>
      <div className="site-mobile-menu site-navbar-target d-none">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <header
        className={`site-header ${scroll > 400 ? "sticky-bar" : ""} ${
          isHome ? "" : "home-header shadow-nav"
        }`}
      >

          <div className="header-top d-none d-lg-block">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12">
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div className="header-info-left">
                      <ul>
                        <li>
                          <i className="fas fa-phone-alt"></i> +374 (10) 517-196
                        </li>
                        <li>
                          <a href="mailto:info@roundtable-act.am" className="__cf_email__">info@roundtable-act.am</a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-info-right">
                      <ul className="header-social">
                        <li>
                          <SearchInput />
                        </li>
                        {/* <li>
                          <a href="https://old.roundtable-act.am/" target="_blank">{t('oldSite')}</a>
                        </li> */}
                        <li>
                          <a href="#"><LanguageSwitch /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="nav-bar">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div className="site-branding d-flex align-items-center">
                  <Link className="d-block" to="/">
                    <img className="d-block logo" src={Logo} alt="logo" />
                  </Link>
                </div>
                <nav
                  className={`${
                    isOpen ? "show" : null
                  } site-navigation d-flex justify-content-end align-items-center`}
                >
                  <ul className="d-flex flex-column flex-lg-row justify-content-lg-end align-content-center overflow-visible">
                    {menuItems &&
                      menuItems.map((item: any, index) => {

                        item = getMenuLocalization(item, lng)

                        let submenu = item.attributes?.menu_items?.data

                        submenu = submenu.map(((sub_item: any) => getMenuLocalization(sub_item, lng)))

                        return (
                          <React.Fragment key={item.id}>
                            <li className="block overflow-visible" id={item.id}>
                              <Link
                                onClick={() => setIsOpen((pre) => !pre)}
                                to={`${checkRoute(item)}`}
                              >
                                {item.attributes.title}
                              </Link>
                              {isTabletOrMobile && submenu.length ? (
                                <div
                                  className="arrow"
                                  onClick={() => mobileSideBarHandler(item.id)}
                                >
                                  <BiSolidDownArrow />
                                </div>
                              ) : null}

                              {submenu.length && !isTabletOrMobile ? (
                                <div className="sub-menu-block">
                                  {submenu.map((subItem: any) => (
                                    <li id={subItem.id}>
                                      <React.Fragment key={subItem.id}>
                                      <Link to={`${checkRoute(subItem)}`}>
                                        {subItem.attributes.title}
                                      </Link>
                                    </React.Fragment>
                                    </li>
                                    
                                  ))}
                                </div>
                              ) : submenu.length && isTabletOrMobile ? (
                                <div
                                  className={`submenuMobile ${
                                    mobileSidebarOpen === item.id &&
                                    "sidebarOpen"
                                  }`}
                                >
                                  {submenu.map((subItem: any) => (
                                    <React.Fragment key={subItem.id}>
                                      <li>
                                        <Link
                                          to={`${checkRoute(subItem)}`}
                                          onClick={() => {
                                            setIsOpen((pre) => !pre)
                                            mobileSideBarHandler(0)
                                          }}
                                        >
                                          {subItem.attributes.title}
                                        </Link>
                                      </li>
                                      
                                    </React.Fragment>
                                  ))}
                                </div>
                              ) : null}
                            </li>
                          </React.Fragment>
                        )
                      })}
                    <li>
                      <Link to={t('donateLink')} className="btn gradient-bg mr-2 d-xl-none">
                        {t('donate')}
                      </Link>
                      
                      <div className="d-lg-none">
                        <LanguageSwitch />
                      </div>
                    </li>
                  </ul>
                  
                  <Link to={t('donateLink')} className="btn gradient-bg mr-2 d-none d-xl-block">
                    {t('donate')}
                      </Link>
                </nav>

                <div
                  className={`hamburger-menu d-xl-none ${
                    isOpen ? "open" : null
                  }`}
                  onClick={handleClick}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
