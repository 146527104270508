import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { getImageAndVideoGallery, getPage } from "../../redux/actions"
import {ImageGallery, Sidebar, VideoGallery} from "../../components"
import { useTranslation } from "react-i18next"
import { getArticleLocalization } from "../../Api"
import { ImageGalleryNew } from "../../components/ImageGalleryNew"
import { ImagePageGallery } from "../../components/ImagePageGallery"
import { Helmet } from "react-helmet"

export const GeneratedPage = () => {
  const { id, locale } = useParams()
  const { lng } = useAppSelector((store) => store.language)
  const [t, i18n] = useTranslation();
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch()
  let {
    getPage: { page }
  } = useAppSelector((state: any) => state)
  const imageGallery = useAppSelector(
    (store: any) => store.gallery.gallery.data?.attributes?.image_gallery
  )
  const videoGallery = useAppSelector(
    (store: any) => store.gallery.gallery.data?.attributes?.video_gallery
  )

  const changeLng = () => {
    i18n.changeLanguage(locale)
  }

  useEffect(() => {
    dispatch(getPage({id, locale}))
    dispatch(getImageAndVideoGallery(id))
  }, [id])

  if (lng != locale) {
    
    page = getArticleLocalization(page.data, lng)
    navigate(`/${lng}/page/${page.id}`, { replace: true })
  }

  return (
    <>
    <Helmet>
      <title>{`${page.data?.attributes && page.data.attributes?.title} - ${t('siteTitle')}`}</title>
      {/* <meta property="og:image" content={process.env.REACT_APP_BASE_URL + (imageGallery && imageGallery.length) ? imageGallery[0]?.file.data.attributes?.formats?.medium.url : ''}></meta> */}
    </Helmet>
    <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            {page.data?.attributes && <h1>{page.data.attributes?.title}</h1>}
            {page.data?.attributes?.blocks &&
              page.data?.attributes?.blocks.map((el: any) => (
                <React.Fragment key={el.id}>
                  <div dangerouslySetInnerHTML={{ __html: el.body }}></div>
                </React.Fragment>
              ))}
            <React.Fragment>

              <div className="imageGallery col-lg-12 col-md-10">
                <ImagePageGallery galleryID="page-gallery" images={imageGallery} />
                {/* {imageGallery
        ? imageGallery.map((el: any) => (
            <ImageGallery image={el} key={el.id} />
        ))
        : null} */}
              </div>
              <div className="videoGallery">
                {videoGallery
                  ? videoGallery.map((el: any) => (
                    <VideoGallery video={el} key={el.id} />
                  ))
                  : null}
              </div>
            </React.Fragment>
          </div>
          <Sidebar />
        </div>
      </div></>
  )
}
