import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
// import 'photoswipe/style.css';

export const ImagePageGallery = ( props ) => {

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryID,
      children: 'a',
      pswpModule: () => import('photoswipe'),
      spacing: 0.5
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  // const thumbImg = image?.attributes?.formats?.thumbnail
  // const smallImg = image?.attributes?.formats?.small
  // const mediumImg =  image?.attributes?.medium
  // const largeImg =  image?.attributes?.large

  // const bigImg = largeImg ? largeImg : mediumImg || smallImg


  console.log(props)
  return (
    <div className="pswp-gallery" id={props.galleryID}>
      {props.images?.map((image, index) => (
        <a
          href={process.env.REACT_APP_BASE_URL + image?.file.data.attributes?.formats?.medium.url}
          data-pswp-width={image?.file.data.attributes?.formats?.medium.width}
          data-pswp-height={image?.file.data.attributes?.formats?.medium.height}
          key={props.galleryID + '-' + index}
          target="_blank"
          rel="noreferrer"
        >
          <img src={process.env.REACT_APP_BASE_URL + image?.file.data.attributes?.formats?.thumbnail.url} alt="" />
        </a>
      ))}
    </div>
  )
}
