import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import getPageSlice from "./PageSlice"

import getArticlesSlice from "./ArticlesSlice"
import homePageNewsSlice from "./homePageNewsSlice"

import getAllCategoriesSlice from "./getAllCategoriesSlice"
import populatePostsSlice from "./populatePostsSlice"
import ImageAndVideoGallerySlice from "./ImageAndVideoGallerySlice"
import ArticleGallerySlice from "./articleGallerySlice"
import languageSlice from "./languageSlice"
import getCategorySlice from "./getCategorySlice"
import getaSearchArticlesSlice from "./searchArticlesSlice"

export const store = configureStore({
  reducer: {
    getPage: getPageSlice,
    getArticle: getArticlesSlice,
    homePageNews: homePageNewsSlice,
    populatePosts: populatePostsSlice,
    allCategories: getAllCategoriesSlice,
    searchArticles: getaSearchArticlesSlice,
    gallery: ImageAndVideoGallerySlice,
    articleGallery: ArticleGallerySlice,
    language: languageSlice,
    category: getCategorySlice
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
