import { createSlice } from "@reduxjs/toolkit"
import { getHomePageNews } from "./actions"

const initialState: any = {
  data: "",
  meta: {},
  homeNewsLoading: false
}

export const homePageNewsSlice = createSlice({
  name: "homeNews",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHomePageNews.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      state.homeNewsLoading = false
    })
    builder.addCase(getHomePageNews.pending, (state, action) => {
      state.homeNewsLoading = true
    })
    builder.addCase(getHomePageNews.rejected, (state, action) => {
      state.homeNewsLoading = false
    })
  }
})

export default homePageNewsSlice.reducer
