import { createSlice } from "@reduxjs/toolkit"
import { getArticle, getPage } from "./actions"

const initialState = {
  article: {},
  getArticleLoading: false
}

export const getArticlesSlice = createSlice({
  name: "getArticle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArticle.fulfilled, (state, action) => {
      state.article = action.payload
      state.getArticleLoading = false
    })
    builder.addCase(getArticle.pending, (state, action) => {
      state.getArticleLoading = true
    })
  }
})

export const {} = getArticlesSlice.actions

export default getArticlesSlice.reducer
