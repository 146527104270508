import MapImg from "./newmap.svg"
import "./Location.css"
import { useTranslation } from "react-i18next";

export const Location = (): JSX.Element => {
  const [t, i18n] = useTranslation();
  return (
    <div className="home-page-limestone">
      <div className="container">
        <div className="row align-items-center">
          <div className="coL-12 col-lg-6">
            <div className="section-heading">
              <h2 className="entry-title">{t('socialCenters')}</h2>
              <p className="mt-5">
                {t('socialCentersText')}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <object width="100%" data={MapImg} type="image/svg+xml"/>
          </div>
        </div>
      </div>
    </div>
  )
}
