import { useTranslation } from "react-i18next";
import { Causes, Fund, Slider, Location, HomePageNews } from "../../components"
import { Helmet } from "react-helmet";

export const Home = (): JSX.Element => {
  const [t, i18n] = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('siteTitle')}</title>
        <meta name="description" content={t('siteDescription')} />
      </Helmet>
      <Slider />
      <Causes />
      <Fund />
      <HomePageNews />
      <Location />
    </>
  )
}
