import { Link } from "react-router-dom"
import { BiLogoPinterestAlt } from "react-icons/bi"
import { BiLogoFacebook } from "react-icons/bi"
import { AiOutlineTwitter } from "react-icons/ai"
import { AiOutlineDribbble } from "react-icons/ai"
import { BiLogoBehance } from "react-icons/bi"
import { AiFillLinkedin } from "react-icons/ai"
import { BsYoutube } from "react-icons/bs"
import {BsFillTelephoneFill} from 'react-icons/bs'
import {MdLocationPin} from 'react-icons/md'
import {GrMail} from 'react-icons/gr'
import Logo from "./logo.png"
import "./Footer.css"
import { useTranslation } from "react-i18next"

export const Footer = (): JSX.Element => {
  const [t, i18n] = useTranslation();
  return (
    <footer className="site-footer">
      <div className="footer-widgets">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="foot-about">
                <h2>
                  <Link className="foot-logo" to="/">
                    <img src={Logo} alt="" />
                  </Link>
                </h2>
                <ul className="d-flex flex-wrap align-items-center footer-social">
                  <li>
                    <Link to="https://www.facebook.com/ArmeniaRoundTable" target="_blank">
                      <BiLogoFacebook />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/ArmRoundTable" target="_blank">
                      <AiOutlineTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/channel/UCsCvqvqv_YlMamGCGiPmSBg" target="_blank">
                      <BsYoutube />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
              <h2>{t('about')}</h2>
              <ul>
                <li>
                  <Link to={t('aboutLink')}>{t('about')}</Link>
                </li>
                <li>
                  <Link to={t('missionLink')}>{t('mission')}</Link>
                </li>
                <li>
                  <Link to={t('boardLink')}>{t('board')}</Link>
                </li>
                <li>
                  <Link to={t('staffLink')}>{t('staff')}</Link>
                </li>
                <li>
                  <Link to={t('contactLink')}>{t('contact')}</Link>
                </li>
                <li>
                  <Link to={t('faqLink')}>{t('faq')}</Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
              <h2>{t('partners')}</h2>
              <ul>
                <li>
                  <Link to={t('locPartnersLink')}>{t('locPartners')}</Link>
                </li>
                <li>
                  <Link to={t('forPartnersLink')}>{t('forPartners')}</Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
              <div className="foot-contact">
                <h2>{t('contact')}</h2>
                <ul>
                  <li>
                    <BsFillTelephoneFill className="footer-icon"/>
                    <span>{t('tel')}` +374 10 517-196</span>
                  </li>
                  <li>
                    <GrMail className="footer-icon"/>
                    <span>
                      <a href="mailto:info@roundtable-act.am">info@roundtable-act.am</a>
                    </span>
                  </li>
                  <li>
                    <MdLocationPin className="footer-icon"/>
                    <span>
                      {t('address')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                Copyright &copy;
                <script
                  data-cfasync="false"
                  src="js/email-decode.min.js"
                ></script>
                <script>document.write(new Date().getFullYear());</script>
                WCC Armenia Round Table Foundation. All Rights Reserved.
                Developed by
                <Link
                  to="https://arpistudio.com"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  Arpi Studio
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
