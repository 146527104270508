import React, { useEffect, useState } from "react"
import { getAllCategories, getCategory } from "../../redux/actions"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { Sidebar } from "../Sidebar"
import { PaginatedItems } from "./PaginatedItems"
import "./news.css"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getCategoryLocalization, nowDate } from "../../Api"

export const News = () => {
  const { id } = useParams()
  const { lng } = useAppSelector((store) => store.language)
  const [t, i18n] = useTranslation();
  let { allCategories, category } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  if (category) {
    category = getCategoryLocalization(category, lng)
  }

  const createMarkup = () => {
    return { __html: category?.data?.attributes?.description?.slice() }
  }

  useEffect(() => {
    dispatch(getAllCategories({ id, lng }))
  }, [id, lng])

  useEffect(() => {
    dispatch(getCategory(id))
  }, [id])

  return (
    
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="news-category">
            <h1>{category?.data?.attributes?.name}</h1>
            <p><div className="m-0" dangerouslySetInnerHTML={createMarkup()} /></p>
          </div>
          {allCategories.articles.data ? (
            <React.Fragment key={allCategories.articles.data.attributes}>
              <PaginatedItems itemsPerPage={25} items={allCategories.articles.data}>

              </PaginatedItems>
          
            </React.Fragment>
          ) :  (
            <div className="loading">Loading...</div>
         ) }
        </div>
        {/* <Sidebar /> */}
      </div>
    </div>
    
  )
}
