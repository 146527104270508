import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { SingleNews } from "../../components/SingleNews"
import { useEffect } from "react"
import { getArticle } from "../../redux/actions"
import { getArticleLocalization } from "../../Api"
import { useTranslation } from "react-i18next"

export const Articles = (): JSX.Element => {
  const { id, locale } = useParams()
  const navigate: any = useNavigate();
  const [t, i18n] = useTranslation();
  let { article } = useAppSelector((state: any) => state.getArticle)
  const { lng } = useAppSelector((store) => store.language)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getArticle({ id, locale }))
  }, [id, dispatch])

  return Object.entries(article).length ? (
    <>
    <SingleNews article={article} id={id} />
    </>
    
  ) : (
    <></>
  )
}
