import { createSlice } from "@reduxjs/toolkit"
import { getCategory } from "./actions"

const initialState = {
  data: {},
  meta: {},
  categoryLoading: false
}

export const getCategorySlice = createSlice({
  name: "getCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      state.categoryLoading = false
    })
    builder.addCase(getCategory.pending, (state, action) => {
      state.categoryLoading = false
    })
    builder.addCase(getCategory.rejected, (state, action) => {
      state.categoryLoading = false
    })
  }
})

export const {} = getCategorySlice.actions

export default getCategorySlice.reducer
