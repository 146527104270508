import { Helmet } from "react-helmet"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"
import { PageRoute } from "./routes"
import { useTranslation } from "react-i18next";
const App = (): JSX.Element => (
  <>
    <PageRoute />
    {ScrollToTop()}
  </>
)

export default App
