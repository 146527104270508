import { createSlice } from "@reduxjs/toolkit"
import { getAllCategories } from "./actions"

const initialState = {
  articles: {},
  getAllCategoriesLoading: false
}
export const getAllCategoriesSlice = createSlice({
  name: "getAllCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.articles = action.payload
      state.getAllCategoriesLoading = false
    })
    builder.addCase(getAllCategories.pending, (state, action) => {
      state.getAllCategoriesLoading = true
    })
  }
})

export const {} = getAllCategoriesSlice.actions

export default getAllCategoriesSlice.reducer
