import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import common_hy from "./languages/hy/translate.json"
import common_en from "./languages/en/translate.json"
import common_ru from "./languages/ru/translate.json"

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["hy", "en"],
    lng: localStorage.getItem("i18nextLng") || "hy",
    resources: {
      hy: { common: common_hy },
      en: { common: common_en }
    },
    ns: ["common"],
    defaultNS: "common",
    detection: {
      order: ["path", "subdomain", "localStorage", "cookie", "htmlTag"],
      caches: ["localStorage"]
    },
    backend: {
      loadPath: "./languages/{{lng}}/translate.json"
    },
    react: { useSuspense: false }
  })

export default i18n;

// i18n
// .use(initReactI18next)
// .use(LanguageDetector)
// .use(HttpApi)
// .init({
//   supportedLngs: ["hy", "ru", "en"],
//   fallbackLng: "ru",
//   resources: {
//     hy: { common: common_hy },
//     ru: { common: common_ru },
//     en: { common: common_en }
//   },
//   ns: ["common"],
//   defaultNS: "common",
//   detection: {
//     order: ["path", "subdomain", "localStorage", "cookie", "htmlTag"],
//     caches: ["localStorage"]
//   },
//   backend: {
//     loadPath: "./languages/{{lng}}/translate.json"
//   },
//   react: { useSuspense: false }
// })
