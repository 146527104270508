import { Link } from "react-router-dom"
import { dateFormat, getArticleLocalization } from "../../Api"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import axios from "axios";

export const SingleItem = ({ item }: any) => {
  const { lng } = useAppSelector((store) => store.language)
  item = getArticleLocalization(item, lng)
  const [defaultImage, setDefaultImage] = useState(false);
  const { title, publishedDate, cover, old_cover } = item.attributes

  let coverImg
  let oldCover = `${process.env.REACT_APP_OLD_IMAGES_FOLDER}/${old_cover}`

  if (cover.data) {
    coverImg = `${process.env.REACT_APP_BASE_URL}${cover.data.attributes?.formats?.thumbnail?.url}`
  } else {

    try {
      let imageUrl = axios.get(`${process.env.REACT_APP_OLD_IMAGES_FOLDER}/${old_cover}`)
      .then((res: any) => {
        if(res.status == 404) {
          setDefaultImage(true)
        } else {
          setDefaultImage(false)
        }
      })
      .catch(err => {
        setDefaultImage(true)
      })
    } catch (err) {
      
    }
    
  }

  const [t, i18n] = useTranslation();
 
  const createMarkup = () => {
    return { __html: item.attributes?.blocks[0]?.body?.slice() }
  }

  return (
    <div className="news-content">
      <div className="upcoming-events">
        <div className="event-wrap d-flex flex-wrap justify-content-between">
          <figure className="m-0">
            <img src={defaultImage ? '/logo-cover.JPG' : coverImg ? coverImg : oldCover} alt={title} />
          </figure>
          <div className="event-content-wrap">
            <header className="entry-header d-flex flex-wrap align-items-center">
              <h3 className="entry-title w-100 m-0">
                <Link to={`${`/${lng}/articles/${item.id}`}`}>{title}</Link>
              </h3>
              <div className="posted-date">
                <p>{dateFormat(publishedDate)}</p>
              </div>
            </header>
            <div className="entry-content">
              {/* <div className="m-0" dangerouslySetInnerHTML={createMarkup()} /> */}
            </div>
            <div className="entry-footer">
              <Link to={`${`/${lng}/articles/${item.id}`}`} replace>{t('readMore')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
