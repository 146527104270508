import { Home } from "../pages/Home"
import { Header } from "../layout/Header/Header"
import { Routes, Route, useLocation } from "react-router-dom"
import { Footer } from "../layout/Footer/Footer"
import { GeneratedPage } from "../pages/GeneratedPage"
import { PageNotFound } from "../pages/PageNotFound"
import { Articles } from "../pages/Articles"
import { News } from "../components"
import { Loader } from "../components/Loader"
import { Search } from "../components/Search"

export const PageRoute = () => {
  const { pathname } = useLocation()

  return (
    <>
    <Loader/>
      <Header isHome={pathname.slice(1) ? false : true} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path=":locale/page/:id" element={<GeneratedPage />} />
        <Route path=":locale/articles/:id" element={<Articles />} />
        <Route path="/category/:id" element={<News />} />
        <Route path="/search/:search" element={<Search />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </>
  )
}
