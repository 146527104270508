import { useState } from "react"

import ReactPaginate from "react-paginate"
import { SingleItem } from "./SingleItem"
import { useTranslation } from "react-i18next"

function Items({ currentItems }: any) {
  return (
    <>
      {currentItems &&
        currentItems.map((item: any) => (
          <SingleItem key={item.id} item={item} />
        ))}
    </>
  )
}

export function PaginatedItems({ itemsPerPage = 2, items }: any) {
  const [t, i18n] = useTranslation();
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const currentItems = items.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
  }

  return (
    <>
      <Items currentItems={currentItems} />

      {
        currentItems.length ? (
          <ReactPaginate
                  previousLabel={t('previous')}
                  nextLabel={t('next')}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"} 
                />
        ) : null
      }
      
    </>
  )
}

