import { useAppSelector } from "../../redux/hooks"
import "./loader.css"
export const Loader = () => {
  const {
    getPage: { getPageLoading },
    allCategories: { getAllCategoriesLoading },
    articleGallery: { articleImageGalleryLoading },
    gallery: { imageAndVideoGalleryLoading },
    getArticle: { getArticleLoading },
    populatePosts: { populatePostsLoading },
    homePageNews: { homeNewsLoading }
  } = useAppSelector((store) => store)

  const loading = [
    getPageLoading,
    getAllCategoriesLoading,
    articleImageGalleryLoading,
    imageAndVideoGalleryLoading,
    getArticleLoading,
    populatePostsLoading,
    homeNewsLoading
  ].some((l) => l)

  if (!loading) return <></>

  return (
    <div className="container_spiner">
      <div className="loadingspinner">
        <div id="square1"></div>
        <div id="square2"></div>
        <div id="square3"></div>
        <div id="square4"></div>
        <div id="square5"></div>
      </div>
    </div>
  )
}
