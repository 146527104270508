import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { changeLanguageRedux } from "../../redux/languageSlice"
import { useTranslation } from "react-i18next"
import "./languageSwitch.css"
import Armenia from "./armenia.png"
import England from "./united-kingdom.png"

export const LanguageSwitch = () => {
  const dispatch = useAppDispatch()
  const { lng } = useAppSelector((store) => store.language)
  const { i18n } = useTranslation()

  // const handleSelectChange = (event: any) => {
  //   const newValue = event.target.value
  //   dispatch(changeLanguageRedux(newValue))
  //   i18n.changeLanguage(newValue)
  // }

  const handleClick = (lng: any) => {
    dispatch(changeLanguageRedux(lng))
    i18n.changeLanguage(lng)
  }

  return (
    <div className="languagePicker">

      <div className="languagePicker">
        <div className="flag">
          <img onClick={() => handleClick('hy')} src={Armenia} width="24px"></img>
        </div>
        <div className="flag">
          <img onClick={() => handleClick('en')} src={England} width="24px"></img>
        </div>
      </div>
    </div>
  )
}
