import { Link } from "react-router-dom"
import CoursesImg from "./coursesImg.jpg"
import Analytics from "./analysis.png"
import Covid from "./covid-19.png"
import War from "./refugee.png"
import "./Courses.css"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../redux/hooks"

export const Causes = () => {
  const [t, i18n] = useTranslation();
  const { lng } = useAppSelector((store) => store.language)
  return (
    <div className="home-page-icon-boxes m-96-0">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 mt-4 mt-lg-0">
            <div className="cause-content-wrap">
            <figure className="m-0 text-center">
                <img src={Analytics} className="text-center" width="50px" alt="" />
               </figure>
              <header className="entry-header pt-3 d-flex flex-wrap align-items-center text-center">
                <h3 className="entry-title w-100 m-0">
                    {t('annualProgrammes')}
                </h3>
              </header>
              <div className="entry-content text-center">
                <p className="m-0">
                  {t('annualProgrammesText1')}
                </p>
                <p className="m-0">
                {t('annualProgrammesText2')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4 mt-4 mt-lg-0">
            <div className="cause-content-wrap">
            <figure className="m-0 text-center">
                <img src={Covid} className="text-center" width="50px" alt="" />
               </figure>
              <header className="entry-header pt-3 d-flex flex-wrap align-items-center text-center">
                <h3 className="entry-title w-100 m-0"> 
                    {t('covid')} 
                </h3>
              </header>
              <div className="entry-content text-center">
                <p className="m-0">
                  {t('covidText1')}
                </p>
                <p className="m-0">
                {t('covidText2')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4 mt-4 mt-lg-0">
            <div className="cause-content-wrap">
            <figure className="m-0 text-center">
                <img src={War} className="text-center" width="50px" alt="" />
               </figure>
              <header className="entry-header pt-3 d-flex flex-wrap align-items-center text-center">
                <h3 className="entry-title w-100 m-0">
                  {t('war')}
                </h3>
              </header>
              <div className="entry-content text-center">
                <p className="m-0">
                  {t('warText1')}
                </p>
                <p className="m-0">
                  {t('warText2')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="entry-footer mt-5 ml-0 text-center">
                <Link to={t('donateLink')} className="btn gradient-bg mr-2">
                  {t('donate')}
                </Link>
              </div>
      </div>
    </div>
  )
}
