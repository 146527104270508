import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { SinglePopulatePost } from "./SinglePopulatePost"
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch';

import "./sidebar.css"
import { getPopulatePosts } from "../../redux/actions"
import MeiliSearch from "meilisearch"
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export const Sidebar = () => {

  const { lng } = useAppSelector((store) => store.language)
  const [t, i18n] = useTranslation();
  const [books, setBooks] : any = useState([])
  const observerElem = useRef(null)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [offset, setOffset] = useState(0)
  const [lastPage, setLastPage] = useState({})
  const dispatch = useAppDispatch()
  const {
    populatePosts: { articles }
  } = useAppSelector((store): any => store)

  const navigate = useNavigate();

  useEffect(() => {
    i18n.on('languageChanged', function(lng) {
      dispatch(getPopulatePosts(lng))
    })

    dispatch(getPopulatePosts(lng))
  }, [])

  // const searchClient = instantMeiliSearch(
  //  'http://127.0.0.1:7700', 'Password2024!!'
  // )

  // console.log(searchClient)

  // const fetchData = async (search: any) => {
  //   const client = new MeiliSearch({
  //     host: 'http://127.0.0.1:7700', apiKey: 'Password2024!!'
  //   })
  //   const index = await client.getIndex('article')
  //   const booksData: any = await index.search(search)

  //   setBooks([...books,  ...booksData.hits])
  //   setLastPage(booksData)
  // }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    console.log(event.target[0].value)
    console.log(event.target.elements.search.value)
    console.log(event.target.search.value)

    navigate(`/search/${event.target.search.value}`);
  }

  // const handleOnSearch = (string: any, results: any) => {
  //   // onSearch will have as the first callback parameter
  //   // the string searched and for the second the results.
  //   fetchData(string)
  //   console.log(books)
  //   console.log(string, results)
  // }

  // const handleOnHover = (result: any) => {
  //   // the item hovered
  //   // console.log(result)
  // }

  // const handleOnSelect = (item: any) => {
  //   // the item selected
  //   fetchData(item)
  //   // console.log(item)
  // }

  // const handleOnFocus = () => {
  //   // console.log('Focused')
  // }

  // const formatResult = (item: any) => {
  //   console.log(item)

  //   return (
  //     <>
  //       <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
  //       <span style={{ display: 'block', textAlign: 'left' }}>name: {item.title}</span>
  //     </>
  //   )
  // }

  // const Hit = (hit: any) => <Highlight attribute="name" hit={hit} />;
  // // console.log(books)

  return (
    <div className="col-12 col-lg-4">
      <div className="sidebar">
        <div className="popular-posts">
          {/* <h2>Popular Posts</h2> */}
          <ul className="p-0">
            {articles.data &&
              articles.data.map((post: any) => (
                <SinglePopulatePost post={post} key={post.id} />
              ))}
          </ul>
        </div>
        {/* {map} */}
      </div>
    </div>
  )
}

