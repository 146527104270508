import { createSlice } from "@reduxjs/toolkit"
import { getPage } from "./actions"

const initialState = {
  page: {},
  getPageLoading: false
}

export const getPageSlice = createSlice({
  name: "getPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPage.fulfilled, (state, action) => {
      state.page = action.payload
      state.getPageLoading = false
    })
    builder.addCase(getPage.pending, (state, action) => {
      state.getPageLoading = true
    })
    builder.addCase(getPage.rejected, (state, action) => {
      state.getPageLoading = false
    })
  }
})

export const {} = getPageSlice.actions

export default getPageSlice.reducer
