import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import './imageGallery.css'

export const ImageGalleryNew = ( props ) => {

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryID,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <div className="pswp-gallery" id={props.galleryID}>
      {props.images?.map((image, index) => {
        const thumbImg = image?.attributes?.formats?.thumbnail
        const smallImg = image?.attributes?.formats?.small
        const mediumImg =  image?.attributes?.formats?.medium
        const largeImg =  image?.attributes?.formats?.large
      
        const bigImg = largeImg ? largeImg : mediumImg || smallImg || thumbImg

        console.log(bigImg)
        return (
          <a
            href={process.env.REACT_APP_BASE_URL + bigImg.url}
            data-pswp-width={bigImg.width}
            data-pswp-height={bigImg.height}
            key={props.galleryID + '-' + index}
            target="_blank"
            rel="noreferrer"
          >
            <img src={process.env.REACT_APP_BASE_URL + image?.attributes?.formats?.thumbnail.url} alt="" />
          </a>
        )
      })}
    </div>
  )
}
