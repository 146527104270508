import React, { useEffect } from "react"
import { HomePageSingleNews } from "./HomePageSingleItem"
import { getHomePageNews } from "../../redux/actions"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import "./HomePageNews.css"
import axios from "axios"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

export const HomePageNews = () => {

  const { homePageNews } = useAppSelector((state) => state)
  const { lng } = useAppSelector((store) => store.language)
  const [t, i18n] = useTranslation();
  const dispatch = useAppDispatch()
  useEffect(() => {

    i18next.on('languageChanged', function(lng) {
      dispatch(getHomePageNews(lng))
    })

    dispatch(getHomePageNews(lng))
  }, [])

  return (
    <div className="home-page-events">
      <div className="container">
        <div className="section-heading">
          <h2 className="entry-title">{t('news')}</h2>
        </div>
        <div className="row">
          {homePageNews.data &&
            homePageNews.data
              .slice(0, 10)
              .map((singleNews: any) => (
                <HomePageSingleNews news={singleNews} key={singleNews.id} />
              ))}
        </div>
        <div className="entry-footer mt-5">
          <a href="/category/1" className="btn gradient-bg mr-2">
            {t('seeMore')}
          </a>
        </div>
      </div>
    </div>
  )
}
