import { createSlice } from "@reduxjs/toolkit"
import { getArticleGallery } from "./actions"

const initialState = {
  gallery: [],
  articleImageGalleryLoading: false
}

export const ArticleGallerySlice = createSlice({
  name: "articleImageGallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArticleGallery.fulfilled, (state, action) => {
      state.gallery = action.payload
      state.articleImageGalleryLoading = false
    })
    builder.addCase(getArticleGallery.pending, (state, action) => {
      state.articleImageGalleryLoading = true
    })
  }
})

export const {} = ArticleGallerySlice.actions

export default ArticleGallerySlice.reducer
