import { createSlice } from "@reduxjs/toolkit"
import { getPopulatePosts } from "./actions"

const initialState = {
  articles: {},
  populatePostsLoading: false
}

export const populatePostsSlice = createSlice({
  name: "populatePosts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPopulatePosts.fulfilled, (state, action) => {
      state.articles = action.payload
      state.populatePostsLoading = false
    })
    builder.addCase(getPopulatePosts.pending, (state, action) => {
      state.populatePostsLoading = true
    })
  }
})

export const {} = populatePostsSlice.actions

export default populatePostsSlice.reducer
