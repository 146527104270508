import { Link } from "react-router-dom"
import { dateFormat } from "../../Api"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";

export const HomePageSingleNews = ({ news }: any) => {
  const { title, publishedDate, old_cover } = news.attributes
  const [t, i18n] = useTranslation();
  const { lng } = useAppSelector((store) => store.language)
  const currentImg = news.attributes?.cover?.data?.attributes?.formats?.small?.url || news.attributes?.cover?.data?.attributes?.formats?.thumbnail?.url
  const createMarkup = () => {
    return { __html: news.attributes?.blocks[0]?.body?.slice() }
  }

  // news = getLocalization(news, lng)

  return (
    <div className="col-12 col-lg-6">
      <div className="upcoming-events">
        <div className="event-wrap d-flex flex-wrap justify-content-between">
          <figure className="m-0">
            <img src={currentImg ? process.env.REACT_APP_BASE_URL + currentImg : process.env.REACT_APP_OLD_IMAGES_FOLDER + old_cover} alt={title} />
          </figure>
          <div className="event-content-wrap">
            <header className="entry-header d-flex flex-wrap align-items-center">
              <h3 className="entry-title w-100 m-0">
                <Link to={`${`/${lng}/articles/${news.id}`}`}>{title}</Link>
              </h3>
              <div className="posted-date">
                <p>{dateFormat(publishedDate)}</p>
              </div>
            </header>
            <div className="entry-content">
              {/* <div className="m-0" dangerouslySetInnerHTML={createMarkup()} /> */}
            </div>
            <div className="entry-footer">
              <Link to={`${`/${lng}/articles/${news.id}`}`}>{t('readMore')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
