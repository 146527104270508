import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { store } from "./redux/store"
import { Provider } from "react-redux"
import "./assets/i18next"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={"loading...."}>
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>
)

reportWebVitals()
